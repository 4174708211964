import "./App.css";
import RenderRouter from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider, Spin, theme as a } from "antd";
import Theme from "containers/Theme";
function App() {
  return (
    <ConfigProvider>
      <div className="App">
        <Theme>
          <Router>
            <RenderRouter />
          </Router>
        </Theme>
      </div>
    </ConfigProvider>
  );
}

export default App;
