import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import requestService from "api/request";
import Spinner from "components/element/Spinner";

// import { Spinner } from 'components';
import { DateTime } from "luxon";
import { Fragment, useEffect, useState } from "react";
// import { useQuery } from 'react-query';

import { formatBalance } from "utils/common";

const TabDeposit = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await requestService.get("/profile/history-payments");
        if (res && res.data) {
          setData(res.data?.data);
        }
      } catch (error) {}
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <Spinner loading={loading}>
      <TableContainer>
        <Table>
          <TableHead className="bg-[#d5d5d5b3]">
            <TableRow>
              <TableCell>Thời gian</TableCell>
              <TableCell align="right">Số tiền</TableCell>
              <TableCell align="right">Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: any) => {
              const isNote = Boolean(item?.content);
              return (
                <Fragment key={item.id}>
                  <TableRow>
                    <TableCell className={isNote ? 'border-b-0' : ''}>
                      {DateTime.fromISO(item?.createdAt).toFormat('HH:ss dd/MM/yyyy')}
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      <div className='text-success'>{formatBalance(item?.moneyPayment)}</div>
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      {item?.isResolve === 'RESOLVE' ? (
                        <div className='text-lime'>Đã nạp</div>
                      ) : (
                        <div className='text-error'>Lỗi</div>
                      )}
                    </TableCell>
                  </TableRow>
                  {isNote && (
                    <TableRow className='mt-[-4px]'>
                      <TableCell className='pt-0' colSpan={3}>
                        Ghi chú: {item?.content}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Spinner>
  );
};
export default TabDeposit;
