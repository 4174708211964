import { LoadingButton } from "@mui/lab";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { message } from "antd";
import requestService from "api/request";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setUser } from "store/app";

type PopupProps = {
  amount: number;
  gate: string;
  incId: string;
  onClose: any;
  result: string;
  onSuccess: any
};

const PopupBet = ({ amount, onClose, gate, incId, result,onSuccess }: PopupProps) => {
  const { user } = useAppSelector((state) => state.app) as any;
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch()
  const handleClickSubmit = async () => {
    if (user?.money < amount)
      return message.warning("Số tiền trong tài khonar không đủ");
    setLoading(true);
    try {
      const res = await requestService.post("/profile/buy-ticket", {
        data: {
          result,
          price: amount,
          type: gate,
        },
      });
      if (res && res.data) {
        dispatch(setUser({
          ...user,
          money:user?.money - amount
        }))
        message.success("Đã cược thành công");
        onSuccess()
        onClose()
      }
    } catch (error: any) {
      console.log("🚀 ~ handleClickSubmit ~ error:", error)
      message.error(error?.response?.data?.message);
    }

    setLoading(false);
  };

  return (
    <>
      <DialogTitle className="text-center">XÁC NHẬN</DialogTitle>
      <Divider />
      <DialogContent className="text-left text-xs space-y-6 text-secondary-dark">
        <div className="flex items-center">
          <div className="min-w-[120px]">Name: </div>
          <div>{incId}</div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="min-w-[120px] text-secondary-dark">
            Tổng số cược:{" "}
            <span className="text-primary-main font-semibold">{gate}</span>
          </div>
          <div>
            Tổng số tiền:{" "}
            <span className="text-primary-main font-semibold">{amount}</span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="inherit"
          fullWidth
          onClick={onClose}
        >
          Hủy
        </LoadingButton>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={handleClickSubmit}
          loading={loading}
        >
          OK
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupBet;
