import { AddAPhoto } from "@mui/icons-material";
import { CardMedia, Grid, IconButton } from "@mui/material";

import { Controller, useForm } from "react-hook-form";

import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { formatBalance } from "utils/common";

const HOME_LINKS = [
    {
      img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFQSURBVHgBpVWBcYMwDJS5DMAIdANGoBtkA5oJmk4AmaDtBt2AbsBlgnYD6ATNBu6rln2GYAeTv/vYEeIly7ZQNIPWusDwCua0jBE8KaXGpYfZgq0BKwpjLwHDQFYV2IE9+MtjxNf5COtJhjA8YexvZBVCAX5wQtawAznCiJo8SAYdi0eyLMFP+B88f1dvZV+Ew6M4FGTqWAQEL+BLaFN2c4M4HmgjMroDXH/ZzM7V0e4WJQLvtNrgCxxkXm0S9MQa+Z/LUeqSBedinn3410kRjIhZe71acIVYYw1rBQc5xPEgawTxvHRLionRwsEOoJTxAoFnMveeu06Li3CaR59kiPlRbEfP1ukpBhG+QixD7c3PZO7wN5nG8OMF48aQu7utN94UT7D3NyuTyCVtR0Fe++Ilv4N7rguZ70UKShFrrUHxjzZdu6Z08OreUL+zNfwB6JR3W7r4lrsAAAAASUVORK5CYII=',
      content: 'Chi tiết',
      contentMore: 'quỹ',
      color: '#765ED6',
      link: '/fund',
    },
  {
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAWCAYAAADNX8xBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADdSURBVHgB7VTREYIwDE04B2CEjsAIMIEjiBPoJrKKE3BO4Ah2BDeoL5p6QsuV1i/vfHchaZq+hncQJsA518IdYDWlsWdmO09WIOnhR1ibIGi0ZsQZE+wiKRu3BImvu0qtmpl0pN7SOtxhncaTzirKhOoTkGUTLZFtKA+NaDXLGdgpIELhEW6ryzNuH3wcIbb0+mTqVEfOB0o4RC5+dsjvgLmjAvjzRWLHkNJoCZ/a0TcduXnir1GAH9BIiGTGNFQOIw/WeS3tWVo/4DykAflpe5aVzu0d5UPeZoC+lweyJXmxXgBgxwAAAABJRU5ErkJggg==",
    content: "Hồ sơ",
    contentMore: "cá cược",
    color: "#4AAD76",
    link: "/history-bet",
  },
  {
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEpSURBVHgBpZSBEYIwDEWD5wCM0A1kA9lAN9ANdANwAnUCR/CcQDfAEdxANqg/ZyKVQ0jx3f2r2OSTkrYJ/cB7n2NYQA5KoTt0TJLkQWOB6cG/eUIVdPUNJY2BE8VgD6XB/w4qdI5ikGSm6InZS0xOEcaa5HpiUvlEvVVPWs8ZdOtrEOZqDDzvKMKYk1IaZjCubfzgSsKmtZHPlNN7+9nghgx1Pdg1jmIIGliGydK0w9CusZp7ORyV7ATmZPGYdJiWGNbyWLdGZuljTp8stdKqoHlHTCZzTGU11uWuDLFr6EyGQO30hv4EHluW3g9Pa1OMBRa6LCaLNNmGTfwyBVNoBtW4A+wnqaGA0ec3VMJnp2+6smgEvrmfP5UqXPGFY2gEXJ1UzCs+hnMvR+JtGug2NsUAAAAASUVORK5CYII=",
    content: "Hồ sơ",
    contentMore: "cá nhân",
    color: "#F7AA40",
    link: "/document-personal",
  },
  {
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC8SURBVHgB7ZPhDYIwEIXvDP91BDaRDXQDR7BOACPoBMzABLCBbqAb6Abna3LqaQjSpj8k8Uteeq8tL6WXMn1BRBYYHDSHDsx8oVgQtobO8sLXGwoFH+VQqyFHaKlzj/Da+7FhDrqqtj3rpVmvhoIKPY3o6XIa/oPaXMNzL+uGCkOpczfoROMoTL1Dw/aZCekonM7UQtNDO+xifV+g724b6z0zSsw/8AcDsw/f0PsTCvUTgPXprCgNTeqmyB2UDs/v4neTJAAAAABJRU5ErkJggg==",
    content: "Tài khoản",
    contentMore: "ngân hàng",
    color: "#7AD0F3",
    link: "/management-bank",
  },
  //   {
  //     img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAWCAYAAADwza0nAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADoSURBVHgBtVSLDYIwFHwlDOAIHYER2EA3YAWdQJxARpANZAJwA0dgBDeod/owDeHTknjJ5aUtl7vy2orMwDl3JCUWELXk3HoiG2E8hxylAK1OZVqfI01jjKkSFZUojHWQdbifk/viCu68BIt7TDVeD/uTRIBRLYUSiURFmR8zVFiDFJ1jhJ92wI2iUt17XRu3g/M1/kU3OAoGF5Qc7BZMuN5q6+Yx1Q5tGZHHHjkme4FFlBBbooh7tlsOuQX7YCH7DFYqvKV6WfcT32YqaL0x+10i8iOVcNzpRJGsRPvPC7AUtZHh0k7gDaZ1eQJdxTDfAAAAAElFTkSuQmCC',
  //     content: 'Quản lý',
  //     contentMore: 'tiền',
  //     color: '#439DF7',
  //     link: '/account/transfer-money',
  //   },
  //   {
  //     img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAVCAYAAABCIB6VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAETSURBVHgBrZONEYIwDIVTFpAROoIbyAaygW4AG+gGjsA5gW7ACLgBbIAb1ETSuxyW0mLf3TugJF9/kgIklDGmJtN7BomEwCs+bugdpBJBzaSLHft7xbxSC1SQQnKlbMMTpYGKMQuvYYsEtF2bbBNUbt0LxcEcXaEbTnygT2jtShZbb33QEj1yQM/BnfhuXMkCvgg1DDrM/mkGkxpwH8/eNWgTyflCYs6TjksxrqQzr0avxBUcV0EgmArUBcb2S8cxF11p2tobwjSgdUhgFhMcswgCvwhMZ+gL5BpQ9Z8QIq74yFX3dYXtHA2hEn38k8jdYC9KCbFieC8uSiu+x1iomsE1Pgr0EaZCDTDV4K6UCu2crz5bxbChR6ru/QAAAABJRU5ErkJggg==',
  //     content: 'Mật khẩu',
  //     contentMore: 'đăng nhập',
  //     color: '#FF6A84',
  //     link: '/account/change-password',
  //   },
  //   {
  //     img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAERSURBVHgBtVQLEcIwDE05BFRCJUzCUMChgKGAQwGgAFAAKEACoAAcMAfgoLzc0uPfdut4d2/d1vQtfU2myANrbY6hDxpQg2dwpZQqqS4gtrQVruAJ3NsHZnW0WGwmCxegfnpvwKmbixUzsmDqiVlITE4Rgi7YeGK0WPGRZedLfAYefMZj7oaB502MYCxYVLcp+BWKL2LuWL6Yydw5sPY9bgMrtgpiBR7WVG0hJPILRtgjKdgLJUJOfec8LCkdvDvd+qEEBaVzduDaV+wOXQqDWzCXe66CAaVkSK/FewsFx2Q4oSpLFp4nC0pPjygSfzll9iWjdBi+KOnjPVXFXVIzcELsceF+DgWGITUH73IJv493peiuQ4wJS1sAAAAASUVORK5CYII=',
  //     content: 'Mật khẩu',
  //     contentMore: 'rút tiền',
  //     color: '#FFA9C6',
  //     link: '',
  //   },
  //   {
  //     img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAYAAABiS3YzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADXSURBVHgBtZTRDcIwDESdqAOwAht0hDJB2QCYAEaBTcoEZYRuQEZgg3COsIiQgxRDnmQ5zcfFOddxBGKMA9IRsSIbV+fcWT48BPfIM2IgGz1izDc6xA4RcNKaDKCo+XPPv7L12iqdiOLEO3Kgevj6UnHyVkQD2QSZJRcHb1GcsKEfyL311IAmoh0ZUQZGGnYyVVoYGGnYaK1UHRhpltXTB30ZGGulF8RWGRj2dTFVimvfkA6KIDMlH7RHoZZcp9nPz6b39Ee4USXTa0lN4oUvmG6BBSdePAF3FVgRUOujUgAAAABJRU5ErkJggg==',
  //     content: 'Cài đặt',
  //     contentMore: 'ngôn ngữ',
  //     color: '#378459',
  //     link: '',
  //   },
  {
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADCSURBVHgBzZRhDcIwEIXvGgRMwiQgYVOABRxsDqgDcAAoICgYEnAADsDB8Y61f7oA2a4J+5K3W5vm5fqaHBMQkQqlgQqazoGZjwyzNRZ76AldaRplUK3dddCNjMDjAZ1cWN/Jjt6ucJSZRbqBtluU1YfzZwS/oy+M7VB+HdBX7t4/zDUZiD5/zzBlkKm1w0Gm888wp2GpH+ujRJbUTyrvEjOPsqXxY+wCVTq+4rRReenZkIVgJlnMEkO7WTBsoYYy8QLW4XXK11hCuQAAAABJRU5ErkJggg==",
    content: "Dịch vụ",
    contentMore: "KH trực tuyến",
    color: "#573FB8",
    link: "#",
  },
];

const Account = () => {
  const navigate = useNavigate();

  const { control } = useForm({ mode: "onChange" });

  const {user} = useAppSelector((state)=> state.app) as any

  return (
    <div>
      <div className="h-[70px] bg-white -container flex items-center p-4">
        <IconButton color="inherit" onClick={() => navigate(-1)}>
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
        </IconButton>
        <div className="text-sm text-primary-main font-semibold uppercase">
          Tài khoản cá nhân
        </div>
      </div>
      <div className="mt-4 flex space-x-3 items-center">
        <div className="h-20 w-20 rounded-full">
          <Controller
            name="avatar"
            defaultValue="https://picsum.photos/200/300"
            control={control}
            rules={{ required: true }}
            render={({ field: { value: avatar }, fieldState: { error } }) => (
              <>
                <input hidden type="file" id="avatar" accept="image/*" />
                <label
                  htmlFor="avatar"
                  className="cursor-pointer"
                  style={error ? { borderColor: "orangered" } : {}}
                >
                  <CardMedia
                    image={avatar}
                    className="flex items-center h-20 rounded-full justify-center bg-[#D9D9D9] bg-contain"
                  >
                    {!avatar && (
                      <div className="flex flex-col items-center justify-center space-y-2">
                        <AddAPhoto sx={{ color: "silver" }} fontSize="large" />
                        <div className="text-secondary-main text-xs font-medium">
                          Thêm ảnh
                        </div>
                      </div>
                    )}
                  </CardMedia>
                </label>
              </>
            )}
          />
        </div>
        <div>
          <div className="text-secondary-dark text-sm font-medium">
            Tên tài khoản: {user?.phone}
          </div>
          <div className="text-secondary-dark text-md font-medium">
            Số dư: <span className="text-primary-main">{formatBalance(user?.money)} USD</span>
          </div>
          <div className="text-secondary-dark text-sm font-medium">
            ID:{" "}
            <span className="text-primary-main">{user?.id_custom}</span>
          </div>
          <div className="text-secondary-dark text-sm font-medium">
            Mã giới thiệu: <span className="text-primary-main">{user?.parentCode || ""}</span>
          </div>
        </div>
      </div>

      <Grid container columnSpacing={7} rowSpacing={3} className="mt-1">
        {HOME_LINKS.map((item, index) => (
          <Grid item xs={4} className="flex flex-col items-center" key={index}>
            <Link to={item.link} className="w-full">
              <div className="bg-[#FFEFF5] h-[80px] flex justify-center items-center rounded-[20px]">
                <div
                  style={{ backgroundColor: item.color }}
                  className="h-[35px] w-[35px] flex justify-center items-center rounded-full"
                >
                  <img src={item.img} />
                </div>
              </div>
            </Link>
            <div className="text-center text-xs text-secondary-dark font-medium mt-2">
              <div>{item.content}</div>
              <div>{item.contentMore}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Account;
