import { AddAPhoto } from '@mui/icons-material';
import { CardMedia, Divider, IconButton } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';

import { formatBalance } from 'utils/common';

const DocumentPersonal = () => {
  const navigate = useNavigate();
    const {user} = useAppSelector((state)=>state.app) as any
  const { control } = useForm({ mode: 'onChange' });



  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>HỒ SƠ CÁ CƯỢC</div>
      </div>
      <div className='mt-4 flex space-x-3 items-center'>
        <div className='h-20 w-20 rounded-full'>
          <Controller
            name='avatar'
            defaultValue='https://picsum.photos/200/300'
            control={control}
            rules={{ required: true }}
            render={({ field: { value: avatar }, fieldState: { error } }) => (
              <>
                {/* <input
                  hidden
                  type='file'
                  id='avatar'
                  accept='image/*'
                  onChange={(e) => handleChangeFile(e, 'avatar')}
                /> */}
                <label htmlFor='avatar' className='cursor-pointer' style={error ? { borderColor: 'orangered' } : {}}>
                  <CardMedia
                    image={avatar}
                    className='flex items-center h-20 rounded-full justify-center bg-[#D9D9D9] bg-contain'
                  >
                    {!avatar && (
                      <div className='flex flex-col items-center justify-center space-y-2'>
                        <AddAPhoto sx={{ color: 'silver' }} fontSize='large' />
                        <div className='text-secondary-main text-xs font-medium'>Thêm ảnh</div>
                      </div>
                    )}
                  </CardMedia>
                </label>
              </>
            )}
          />
        </div>
        <div>
          <div className='text-secondary-dark text-sm font-medium'>Tên tài khoản: {user?.phone}</div>
          <div className='text-secondary-dark text-sm font-medium'>
            Số dư: <span className='text-primary-main'>{formatBalance(user?.money)} USD</span>
          </div>
          <div className='text-secondary-dark text-sm font-medium'>
           ID: <span className='text-primary-main'>{user?.id_custom}</span>
          </div>
        </div>
      </div>

      <div className='bg-[#FF91A4] rounded-md mt-6 text-white text-sm font-medium'>
        <div className='flex items-center justify-between p-4'>
          <div className='whitespace-nowrap'>Tài khoản:</div>
          <div className='text-right'>{user?.bankInfo?.nameBank ?? "Chưa thêm ngân hàng" }</div>
        </div>
        <Divider sx={{ borderColor: '#FFBBC7' }} />
      
        <Divider sx={{ borderColor: '#FFBBC7' }} />
        <div className='flex items-center justify-between p-4'>
          <div className='whitespace-nowrap'>Tiền tệ:</div>
          <div className='text-right'>USD</div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPersonal;
