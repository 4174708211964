import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import requestService from "api/request";
import Spinner from "components/element/Spinner";

import { DateTime } from "luxon";
import { Fragment, useEffect, useState } from "react";
import { useAppSelector } from "store";
// import { useQuery } from 'react-query';

import { formatBalance } from "utils/common";

const TabWithdraw = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const {isFetchWithDraw} = useAppSelector((state)=> state.app)
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await requestService.get("/profile/history-withdraws");
        if (res && res?.data) {
          setData(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getData();
  }, [isFetchWithDraw]);

  return (
    <Spinner loading={loading}>
      <TableContainer>
        <Table>
          <TableHead className="bg-[#d5d5d5b3]">
            <TableRow>
              <TableCell>Thời gian</TableCell>
              <TableCell align="right">Số tiền</TableCell>
              <TableCell align="right">Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item:any) => {
              const isNote = Boolean(item?.note);
              return (
                <Fragment key={item._id}>
                  <TableRow>
                    <TableCell className={isNote ? 'border-b-0' : ''}>
                      {DateTime.fromISO(item.createdAt).toFormat('HH:ss dd/MM/yyyy')}
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      <div className='text-success'>{formatBalance(item.moneyWithDraw)}</div>
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      {item.isResolve === 'RESOLVE' ? (
                        <div className='text-lime'>Đã rút</div>
                      ) : item.isResolve === 'REJECT' ? (
                        <div className='text-error'>Từ chối</div>
                      ) : (
                        <div className='text-neutral'>Chờ</div>
                      )}
                    </TableCell>
                  </TableRow>
                  {isNote && (
                    <TableRow className='mt-[-4px]'>
                      <TableCell className='pt-0' colSpan={3}>
                        Ghi chú: {item.note}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Spinner>
  );
};
export default TabWithdraw;
