import type { RouteObject } from "react-router";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import Login from "../pages/login";
import WrapperRouteComponent from "./WrapperRouteComponent";

import Register from "pages/register";
import Home from "pages/login/Home";
import Layout from "components/layout/Layout";
import Casino from "pages/Casino/Casino";
import Award from "pages/Award";
import HistoryBet from "pages/HistoryBet/HistoryBet";
import Account from "pages/Account/Account";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import requestService from "api/request";
import { setUser } from "store/app";

import ManagementBank from "pages/Account/components/ManagementBanks";
import DocumentPersonal from "pages/Account/components/DocumentPersonal";
import Fund from "pages/Account/components/Fund";
const routeList: RouteObject[] = [
  {
    path: "/",
    element: <WrapperRouteComponent element={<Layout />} />,
    children: [
      {
        path: "/login",
        element: <WrapperRouteComponent element={<Login />} />,
      },
      {
        path: "/register",
        element: <WrapperRouteComponent element={<Register />} />,
      },
      {
        path: "/",
        element: <WrapperRouteComponent element={<Home />} />,
      },
      {
        path: "/casino",
        element: <WrapperRouteComponent element={<Casino />} />,
      },
      {
        path: "/award",
        element: <WrapperRouteComponent element={<Award />} />,
      },
      {
        path: "/history-bet",
        element: <WrapperRouteComponent element={<HistoryBet />} />,
      },
      {
        path: "/account",
        element: <WrapperRouteComponent element={<Account />} />,
      },
      {
        path: "/management-bank",
        element: <WrapperRouteComponent element={<ManagementBank/>} />,
      },
      {
        path: "/document-personal",
        element: <WrapperRouteComponent element={<DocumentPersonal/>} />,
      },
      {
        path: "/fund",
        element: <WrapperRouteComponent element={<Fund/>} />,
      },
    ],
  },
];
const RenderRouter = () => {
  const element = useRoutes(routeList);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const getUser = async () => {
    try {
      const res = await requestService.get("/profile/me");
      if (res && res.data) {
        dispatch(setUser(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("tokens")) {
      if (location.pathname.includes("/login")) navigate("/");
      getUser();
    } else {
      navigate("/login");
    }
  }, []);

  return element;
};

export default RenderRouter;
