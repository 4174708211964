import { AppBar, Container } from '@mui/material';
import Menu from './Menu';


const Footer = () => {
  return (
    <AppBar
      component={Container}
      color='transparent'
      className='fixed left-0 bottom-0 right-0 top-[unset] bg-white px-0 rounded-t-xl shadow-none'
    >
      <Menu />
    </AppBar>
  );
};

export default Footer;
