import { LoadingButton } from "@mui/lab";
import { Container, FormControl, TextField } from "@mui/material";
import { message } from "antd";
import requestService from "api/request";
import InputPassword from "components/element/InputPassword";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const { control, handleSubmit } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClickSubmit();
    }
  };
  const login = async (values: any) => {
    setLoading(true);
    try {
      const res = await requestService.post("/auth/signup", {
        data: {
          phone: values?.username,
          password: values?.password,
          refCode: values?.fatherId,
        },
      });
      if (res && res.data) {
        message.success('Đăng kí thành công')
        navigate("/login");
      }
    } catch (error: any) {
      message.error(error?.respone?.data?.message);
    }
    setLoading(false);
  };
  const handleClickSubmit = () => {
    handleSubmit((values) => {
      login(values);
    })();
  };

  return (
    <div>
      <div className="h-[70px] bg-white flex items-center px-4">
        <Link to={"/login"}>
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
        </Link>
        <div className="text-sm text-primary-main font-semibold uppercase ml-3">
          Đăng ký
        </div>
      </div>
      <Container className="mt-8 flex flex-col items-stretch gap-4 relative w-full">
        <Controller
          name="username"
          defaultValue=""
          control={control}
          rules={{
            required: "Tài khoản không được để trống",
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className="text-xs text-secondary-dark font-medium">
                Tên tài khoản
              </label>
              <TextField
                {...field}
                fullWidth
                placeholder="Vui lòng nhập tài khoản của bạn"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name="password"
          defaultValue=""
          control={control}
          rules={{
            required: "Mật khẩu không được để trống",
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className="text-xs text-secondary-dark font-medium">
                Mật khẩu đăng nhập
              </label>
              <InputPassword
                {...field}
                fullWidth
                placeholder="Vui lòng nhập mật khẩu của bạn"
                onKeyDown={handleKeyDown}
                error={!!error}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name="fatherId"
          defaultValue=""
          control={control}
          rules={{
            required: "Mã giới thiệu không được để trống",
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className="text-xs text-secondary-dark font-medium">
                Mã giới thiệu
              </label>
              <TextField
                {...field}
                fullWidth
                placeholder="Vui lòng nhập mã giới thiệu của bạn"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <LoadingButton
          size="large"
          loading={loading}
          variant="contained"
          className="min-w-[200px] mt-6 rounded-3xl mx-auto"
          disableElevation={false}
          onClick={handleClickSubmit}
        >
          Đăng Ký
        </LoadingButton>
      </Container>
    </div>
  );
};

export default Register;
