import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import requestService from "api/request";
import Spinner from "components/element/Spinner";
import TableRowEmpty from "components/element/TableRowEmpty";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { formatBalance } from "utils/common";

export const convertResult = (result: string) => {
  switch (result) {
    case "LONG":
      return "LONG";
    case "HO":
      return "HỔ";
    case "TREN":
      return "TRÊN";
    case "DUOI":
      return "DƯỚI";
    case "CHAN":
      return "CHẴN";
    case "LE":
      return "LẺ";
    default:
      for (let i = 0; i < 10; i++) {
        if (result === "TRAM_NGAN_" + i) {
          return "Số " + i;
        }
      }
      return "";
  }
};

const HistoryBet = () => {
  const navigate = useNavigate();
  const [history, setHistory] = useState<any>([]);
  const [loading,setLoading] = useState(false)
  const getData = async () => {
    setLoading(true)
    try {
      const res = await requestService.get("/profile/history-lottery");
      if (res && res.data) {
        setHistory(res.data?.data?.hisotry);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="h-[70px] bg-white -container flex items-center p-4">
        <IconButton color="inherit" onClick={() => navigate(-1)}>
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
        </IconButton>
        <div className="text-sm text-primary-main font-semibold">LỊCH SỬ</div>
      </div>
      <div className="text-secondary-dark font-semibold text-sm my-3 text-center">
        Phiếu cá cược ngay lập tức
      </div>
      <Spinner loading={loading}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Số kỳ</TableCell>
                <TableCell>Lệnh đặt</TableCell>
                <TableCell>Số tiền đặt cược</TableCell>
                <TableCell>Thưởng</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                !!history?.length && history?.map((item : any,index: number)=>(
                  <TableRow key={index}>
                  <TableCell>{item?.result?.id_custom}</TableCell>
                  <TableCell>{convertResult(item?.type)}</TableCell>
                  <TableCell>{formatBalance(item?.price)}</TableCell>
                  <TableCell>{formatBalance(item?.priceWin - item?.price)}</TableCell>
                </TableRow>
                ))
              }
          
              <TableRowEmpty visible={false} />
            </TableBody>
          </Table>
        </TableContainer>
      </Spinner>
    </div>
  );
};

export default HistoryBet;
