import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { message } from "antd";
import requestService from "api/request";
import Spinner from "components/element/Spinner";
import { useState } from "react";
import banks from 'bank.json'
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { setUser } from "store/app";

const ManagementBank = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { control, setValue, handleSubmit } = useForm({ mode: "onChange" });
  const { user } = useAppSelector((state) => state.app) as any;

  const dispatch = useDispatch();

  const updateBank = async (values: any) => {
    setLoading(true);
    try {
      const res = await requestService.post("/profile/update-bank", {
        data: {
          ...values,
        },
      });
      if (res && res.data) {
        dispatch(setUser(res?.data?.data));
        message.success("Đã cập nhật thành công");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateBank(values);
    })();
  };
  if (!user)
    return (
      <div className="h-screen w-full  flex justify-center items-center">
        <Spinner loading width={200} />
      </div>
    );
  return (
    <>
      <div className="h-[70px] bg-white -container flex items-center p-4">
        <IconButton color="inherit" onClick={() => navigate(-1)}>
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
        </IconButton>
        <div className="text-sm text-primary-main font-semibold">
          QUẢN LÝ THẺ NGÂN HÀNG
        </div>
      </div>
      <div className="text-secondary-dark font-semibold text-sm my-3 text-center">
        Cài đặt thông tin ngân hàng
      </div>
      <div className="space-y-4 flex flex-col items-stretch">
        <Controller
          control={control}
          name="nameBank"
          defaultValue={user?.bankInfo?.nameBank || ""}
          rules={{
            required: "Ngân hàng không được để trống",
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className="mb-2 text-xs font-semibold">
                Tên ngân hàng
              </label>
              <Select
                {...field}
                color="warning"
                displayEmpty
                sx={{
                  "& .MuiSelect-root": {
                    color: "var(--color-text-primary)",
                    background: "#FFFC",
                    borderRadius: "10px",
                  },
                }}
                error={!!error}
                // Chỉ thêm dòng này để hiển thị lựa chọn đầu tiên trống
                renderValue={(value) => (value === "" ? "Chọn ngân hàng" : value)}
              >
                <MenuItem value="" disabled>
                  Chọn ngân hàng
                </MenuItem>
                {
                  banks.data.map((item, index) => (
                    <MenuItem value={item.shortName}>{item.name}</MenuItem>
                  ))
                }

                {/* Thêm các lựa chọn khác nếu cần */}
              </Select>
              {error && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="numberBank"
          defaultValue={user?.bankInfo?.numberBank || ""}
          rules={{
            required: "Số thẻ ngân hàng không được để trống",
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className="mb-2 text-xs font-semibold">
                Số thẻ ngân hàng
              </label>
              <TextField
                {...field}
                color="warning"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "var(--color-text-primary)",
                    background: "#FFFC",
                    borderRadius: "10px",
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="authorName"
          defaultValue={user?.bankInfo?.authorName || ""}
          rules={{
            required: "Tên chủ thẻ không được để trống",
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className="mb-2 text-xs font-semibold">Tên chủ thẻ</label>
              <TextField
                {...field}
                color="warning"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "var(--color-text-primary)",
                    background: "#FFFC",
                    borderRadius: "10px",
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <div className="text-sm">* Quý khách vui lòng điền đúng thông tin</div>
      </div>
      <div className="flex mt-10">
        <LoadingButton
          size="large"
          className="rounded-full mx-auto min-w-[200px]"
          onClick={handleClickSubmit}
          variant="contained"
          loading={loading}
        >
          Lưu thông tin
        </LoadingButton>
      </div>
    </>
  );
};

export default ManagementBank;
